/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import Content from 'components/Content'
import styled from 'styled-components';
import GravityForm from 'components/GravityForm';

import Layout from 'components/Layout'
import 'styles/VodCasts.scss';
import HeroTitle from '../components/HeroTitle';

const StyledImage = styled(Image)`
  @media (max-width: 991px) {
    height: 350px;
  }
`

const NotVisible = styled.div`
  background-color: rgba(0, 0, 0, 0.56);
  position: absolute;
  top: 0;
  height: 90%;
  width: 100%;
  border-radius: 20px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6rem;
  color: #FEFEFE;

  @media (max-width: 991px) {
    padding: 0 2rem;
  }

  & h3 {
    font-size: 30px;

    span {
      color: #C9E700;
    }
  }

`

export default function VodCast({ data }) {
  const { vodcast, page, allVodcasts } = data
  const [isVisible, setVisible] = React.useState(false)
  const [isPlaying, setIsPlaying] = React.useState(false);

  const handlePlayClick = () => {
    const video = document.getElementById('videoPlayer');
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (vodcast.acf.visible) {
      setVisible(true)
    }
  }, [vodcast.acf.visible])

  useEffect(() => {
    const formSubmitted = sessionStorage.getItem('formSubmitted')
    if (formSubmitted) {
      setVisible(true)
    }
  }, [])


  return (
    <Layout>
      <HeroTitle fluidImage={page.acf.image.localFile.childImageSharp.fluid} />
      <div className="container vodcastContainer">
        <div className="row vodcastCard">
          <div className="col-12 col-md-6 col-xl-7 mb-4 mb-lg-0">
            <div className="position-relative mb-4 video-tag">
              {isVisible ? (
                <>
                  <video id="videoPlayer" controls poster={vodcast.acf.image.localFile.childImageSharp.fluid.src} style={{ width: '100%', borderRadius: '20px' }}>
                    <source src={vodcast.acf.vodcast_url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div className="play" onClick={handlePlayClick}>
                    {!isPlaying && (
                      <svg id="Laag_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="48.5" style={{ fill: 'none', stroke: '#fff', strokeWidth: '3px' }} />
                        <path d="m71.53,45.67c3.33,1.92,3.33,6.74,0,8.66l-29.25,16.89c-3.33,1.92-7.5-.48-7.5-4.33v-33.77c0-3.85,4.17-6.25,7.5-4.33l29.25,16.89Z" style={{ fill: '#fff' }} />
                      </svg>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <video controls style={{ width: '100%', borderRadius: '20px' }}>
                    <source src={vodcast.acf.vodcast_url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <NotVisible>
                    <h3 className="text-center">
                      <strong>
                        Oeps
                        <span>.</span>
                      </strong>
                    </h3>
                    <p className="text-center mb-4 pb-1">Deze aflevering staat nog niet online. Kun je niet wachten en wil je graag verder kijken? Laat je e-mailadres achter om de volgende afleveringen te bekijken.</p>
                    <GravityForm id={8} oneRow isVisible={isVisible} setVisible={setVisible} />
                  </NotVisible>
                </>
              )}
            </div>
            <h1>{vodcast.acf.title}</h1>
            <div className="d-flex align-items-center mb-4 speakers">
              <div className="d-flex align-items-center">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_510_514)">
                    <path d="M7 0C5.61553 0 4.26216 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.003033 5.6003 -0.13559 7.00777 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C13.998 5.1441 13.2599 3.36479 11.9475 2.05247C10.6352 0.74015 8.8559 0.0020073 7 0V0ZM7 12.8333C5.84628 12.8333 4.71846 12.4912 3.75918 11.8502C2.79989 11.2093 2.05222 10.2982 1.61071 9.23232C1.16919 8.16642 1.05368 6.99353 1.27876 5.86197C1.50384 4.73042 2.05941 3.69102 2.87521 2.87521C3.69102 2.0594 4.73042 1.50383 5.86198 1.27875C6.99353 1.05367 8.16642 1.16919 9.23232 1.6107C10.2982 2.05221 11.2093 2.79989 11.8502 3.75917C12.4912 4.71846 12.8333 5.84628 12.8333 7C12.8316 8.54658 12.2165 10.0293 11.1229 11.1229C10.0293 12.2165 8.54658 12.8316 7 12.8333Z" fill="#7D7D7D" />
                    <path d="M6.99993 3.5C6.84522 3.5 6.69685 3.56146 6.58745 3.67085C6.47805 3.78025 6.41659 3.92862 6.41659 4.08333V6.60625L4.45018 7.83824C4.31867 7.92039 4.22519 8.05142 4.19029 8.2025C4.1554 8.35357 4.18194 8.51232 4.26409 8.64383C4.34625 8.77533 4.47727 8.86881 4.62835 8.90371C4.77942 8.93861 4.93817 8.91206 5.06968 8.82991L7.30968 7.42991C7.39431 7.37688 7.46391 7.30302 7.51182 7.21538C7.55972 7.12775 7.58432 7.02928 7.58326 6.92941V4.08333C7.58326 3.92862 7.5218 3.78025 7.41241 3.67085C7.30301 3.56146 7.15464 3.5 6.99993 3.5Z" fill="#7D7D7D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_510_514">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="ml-2">{vodcast.acf.length_vodcast}</span>
              </div>
              <div className="d-flex align-items-center ml-3">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_510_510)">
                    <path d="M7 6.99994C7.69223 6.99994 8.36892 6.79467 8.9445 6.41008C9.52007 6.0255 9.96867 5.47887 10.2336 4.83933C10.4985 4.19979 10.5678 3.49606 10.4327 2.81712C10.2977 2.13819 9.96436 1.51455 9.47487 1.02507C8.98539 0.535583 8.36175 0.20224 7.68282 0.0671921C7.00388 -0.0678562 6.30015 0.00145547 5.66061 0.266362C5.02107 0.531269 4.47444 0.979873 4.08986 1.55544C3.70527 2.13102 3.5 2.80771 3.5 3.49994C3.50093 4.42791 3.86997 5.31762 4.52615 5.97379C5.18233 6.62997 6.07203 6.99901 7 6.99994ZM7 1.16661C7.46149 1.16661 7.91262 1.30345 8.29633 1.55984C8.68005 1.81623 8.97912 2.18065 9.15572 2.60701C9.33232 3.03337 9.37853 3.50253 9.2885 3.95515C9.19847 4.40777 8.97624 4.82353 8.64992 5.14986C8.32359 5.47618 7.90783 5.69841 7.45521 5.78844C7.00259 5.87847 6.53343 5.83226 6.10707 5.65566C5.68071 5.47906 5.31629 5.17999 5.0599 4.79627C4.80351 4.41256 4.66667 3.96143 4.66667 3.49994C4.66667 2.8811 4.9125 2.28761 5.35008 1.85002C5.78767 1.41244 6.38116 1.16661 7 1.16661Z" fill="#7D7D7D" />
                    <path d="M7 8.16699C5.60809 8.16854 4.27363 8.72216 3.28939 9.70639C2.30516 10.6906 1.75154 12.0251 1.75 13.417C1.75 13.5717 1.81146 13.7201 1.92085 13.8295C2.03025 13.9389 2.17862 14.0003 2.33333 14.0003C2.48804 14.0003 2.63642 13.9389 2.74581 13.8295C2.85521 13.7201 2.91667 13.5717 2.91667 13.417C2.91667 12.334 3.34687 11.2954 4.11265 10.5296C4.87842 9.76387 5.91703 9.33366 7 9.33366C8.08297 9.33366 9.12158 9.76387 9.88735 10.5296C10.6531 11.2954 11.0833 12.334 11.0833 13.417C11.0833 13.5717 11.1448 13.7201 11.2542 13.8295C11.3636 13.9389 11.512 14.0003 11.6667 14.0003C11.8214 14.0003 11.9697 13.9389 12.0791 13.8295C12.1885 13.7201 12.25 13.5717 12.25 13.417C12.2485 12.0251 11.6948 10.6906 10.7106 9.70639C9.72637 8.72216 8.39191 8.16854 7 8.16699Z" fill="#7D7D7D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_510_510">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="ml-2">{vodcast.acf.speakers}</span>
              </div>
            </div>
            <Content content={vodcast.acf.description} />
          </div>
          <div className="col-12 col-md-6 col-xl-5">
            <div className="inner">
              <h3>Volgende afleveringen</h3>
              <div className="vodcasts-list">
                {allVodcasts.edges.map(({ node }) => {

                  const isActive = node.wordpress_id === vodcast.wordpress_id
                  const shouldHide = node.wordpress_id < vodcast.wordpress_id

                  return (
                    <div key={node.id} className={`vodcast-list-item py-4 mb-4 ${shouldHide ? 'd-none' : 'd-block'}  ${isActive ? 'active' : 'not-active'}`}>
                      <Link to={node.path} className="d-flex items-center vodcast-item mb-4">
                        <div className="image">
                          <Image fluid={node.acf.image.localFile.childImageSharp.fluid} alt={node.acf.title} />
                        </div>
                        <div className="vodcast-item-content">
                          <h4>{node.acf.title}</h4>
                          <p className="mb-0">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_510_514)">
                                <path d="M7 0C5.61553 0 4.26216 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.003033 5.6003 -0.13559 7.00777 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C13.998 5.1441 13.2599 3.36479 11.9475 2.05247C10.6352 0.74015 8.8559 0.0020073 7 0V0ZM7 12.8333C5.84628 12.8333 4.71846 12.4912 3.75918 11.8502C2.79989 11.2093 2.05222 10.2982 1.61071 9.23232C1.16919 8.16642 1.05368 6.99353 1.27876 5.86197C1.50384 4.73042 2.05941 3.69102 2.87521 2.87521C3.69102 2.0594 4.73042 1.50383 5.86198 1.27875C6.99353 1.05367 8.16642 1.16919 9.23232 1.6107C10.2982 2.05221 11.2093 2.79989 11.8502 3.75917C12.4912 4.71846 12.8333 5.84628 12.8333 7C12.8316 8.54658 12.2165 10.0293 11.1229 11.1229C10.0293 12.2165 8.54658 12.8316 7 12.8333Z" fill="#7D7D7D" />
                                <path d="M6.99993 3.5C6.84522 3.5 6.69685 3.56146 6.58745 3.67085C6.47805 3.78025 6.41659 3.92862 6.41659 4.08333V6.60625L4.45018 7.83824C4.31867 7.92039 4.22519 8.05142 4.19029 8.2025C4.1554 8.35357 4.18194 8.51232 4.26409 8.64383C4.34625 8.77533 4.47727 8.86881 4.62835 8.90371C4.77942 8.93861 4.93817 8.91206 5.06968 8.82991L7.30968 7.42991C7.39431 7.37688 7.46391 7.30302 7.51182 7.21538C7.55972 7.12775 7.58432 7.02928 7.58326 6.92941V4.08333C7.58326 3.92862 7.5218 3.78025 7.41241 3.67085C7.30301 3.56146 7.15464 3.5 6.99993 3.5Z" fill="#7D7D7D" />
                              </g>
                              <defs>
                                <clipPath id="clip0_510_514">
                                  <rect width="14" height="14" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="ml-2">{node.acf.length_vodcast}</span>
                          </p>
                          <p className="mb-0">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_510_510)">
                                <path d="M7 6.99994C7.69223 6.99994 8.36892 6.79467 8.9445 6.41008C9.52007 6.0255 9.96867 5.47887 10.2336 4.83933C10.4985 4.19979 10.5678 3.49606 10.4327 2.81712C10.2977 2.13819 9.96436 1.51455 9.47487 1.02507C8.98539 0.535583 8.36175 0.20224 7.68282 0.0671921C7.00388 -0.0678562 6.30015 0.00145547 5.66061 0.266362C5.02107 0.531269 4.47444 0.979873 4.08986 1.55544C3.70527 2.13102 3.5 2.80771 3.5 3.49994C3.50093 4.42791 3.86997 5.31762 4.52615 5.97379C5.18233 6.62997 6.07203 6.99901 7 6.99994ZM7 1.16661C7.46149 1.16661 7.91262 1.30345 8.29633 1.55984C8.68005 1.81623 8.97912 2.18065 9.15572 2.60701C9.33232 3.03337 9.37853 3.50253 9.2885 3.95515C9.19847 4.40777 8.97624 4.82353 8.64992 5.14986C8.32359 5.47618 7.90783 5.69841 7.45521 5.78844C7.00259 5.87847 6.53343 5.83226 6.10707 5.65566C5.68071 5.47906 5.31629 5.17999 5.0599 4.79627C4.80351 4.41256 4.66667 3.96143 4.66667 3.49994C4.66667 2.8811 4.9125 2.28761 5.35008 1.85002C5.78767 1.41244 6.38116 1.16661 7 1.16661Z" fill="#7D7D7D" />
                                <path d="M7 8.16699C5.60809 8.16854 4.27363 8.72216 3.28939 9.70639C2.30516 10.6906 1.75154 12.0251 1.75 13.417C1.75 13.5717 1.81146 13.7201 1.92085 13.8295C2.03025 13.9389 2.17862 14.0003 2.33333 14.0003C2.48804 14.0003 2.63642 13.9389 2.74581 13.8295C2.85521 13.7201 2.91667 13.5717 2.91667 13.417C2.91667 12.334 3.34687 11.2954 4.11265 10.5296C4.87842 9.76387 5.91703 9.33366 7 9.33366C8.08297 9.33366 9.12158 9.76387 9.88735 10.5296C10.6531 11.2954 11.0833 12.334 11.0833 13.417C11.0833 13.5717 11.1448 13.7201 11.2542 13.8295C11.3636 13.9389 11.512 14.0003 11.6667 14.0003C11.8214 14.0003 11.9697 13.9389 12.0791 13.8295C12.1885 13.7201 12.25 13.5717 12.25 13.417C12.2485 12.0251 11.6948 10.6906 10.7106 9.70639C9.72637 8.72216 8.39191 8.16854 7 8.16699Z" fill="#7D7D7D" />
                              </g>
                              <defs>
                                <clipPath id="clip0_510_510">
                                  <rect width="14" height="14" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className="ml-2">{node.acf.speakers}</span>
                          </p>
                        </div>
                      </Link>
                      <Content className="content-vodcast" content={node.acf.description} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  )
}

export const query = graphql`
  query VodcastByID($wordpress_id: Int!) {
   vodcast: wordpressWpVodcasts(
        wordpress_id: { eq: $wordpress_id }
    ) {   
      id
      wordpress_id
      title
      slug
      content
      wpml_current_locale
      yoast_meta {
        name
        content
        property
      }
      acf {
        title
        description
        length_vodcast
        speakers
        vodcast_url
        visible
        image {
          localFile {
            childImageSharp {
              fluid(quality: 99, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }

    page: wordpressPage(
      wordpress_id: { eq: 1161 }
    ) {
      title
      wordpress_id
      acf {
        image {
          localFile {
            childImageSharp {
              fluid(quality: 99, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }

    allVodcasts: allWordpressWpVodcasts(sort: {fields: date, order: ASC}) {
      edges {
        node {
          id
          wordpress_id
          slug
          path
          title
          acf {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 99, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            title
            description
            visible
            length_vodcast
            speakers
          }
        }
      }
    }
  }
`
